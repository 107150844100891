/* eslint-disable */
import {NumberColumnConfig, INumberColumnConfig} from './numberColumnConfig';
import { ColumnTemplateType } from './enums';

export class DecimalColumnConfig extends NumberColumnConfig {
  decimalPoints: number;
  hideFraction?: boolean;
  constructor(config: IDecimalColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.Decimal;
    super(config);
    this.decimalPoints = config.decimalPoints;
    this.hideFraction = config.hideFraction;
  }
}

export interface IDecimalColumnConfig extends INumberColumnConfig {
  decimalPoints: number;
  hideFraction?: boolean;
}